.workshop {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.workshop-button {
    width: 12rem;
    padding: 0.5rem;
    border: 1px solid var(--concretegrey);
    border-radius: 0.3rem;
}

.workshop-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
}

.workshop a {
    text-decoration: none;
    color: var(--concretegrey);
}

.workshop a:visited {
    color: var(--concretegrey);
}

.workshop p {
    padding-bottom: 0.5rem;
    text-align: center;
}

.workshops-fine-print p {
    text-align: left;
}

.workshop-text-title {
    font-weight: bold;
    color: #fff;
}

.workshop-text-bold {
    font-weight: bold;
}

.workshop-text-white {
    color: #fff;
}