footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 0.5rem;
}

.social-icons img {
    transform: scale(0.2);
}

.social-icons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-text {
    text-transform: uppercase;
}

/* @media screen and (max-width: 768px) {
    
} */