.gedulgtpriv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.gedulgtpriv-button {
    width: 12rem;
    padding: 0.5rem;
    border: 1px solid var(--concretegrey);
    border-radius: 0.3rem;
    padding-bottom: 0.5rem;
}

.gedulgtpriv-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
}

.gedulgtpriv a {
    text-decoration: none;
    color: var(--concretegrey);
}

.gedulgtpriv a:visited {
    color: var(--concretegrey);
}

.gedulgtpriv p {
    padding-bottom: 0.5rem;
    text-align: center;
}

.gedulgtpriv-fine-print p {
    text-align: left;
}

.gedulgtpriv-text-title {
    font-weight: bold;
    color: #fff;
}

.gedulgtpriv-text-bold {
    font-weight: bold;
}

.gedulgtpriv-text-white {
    color: #fff;
}

@media screen and (max-width: 580px) {
    .gedulgtpriv-table {
        flex-direction: column;
    }
}