:root {
  --rufousred: #9E0A0A;
  --burntorange: #C25000;
  --marigold: #EBA937;
  --mediumchampagne: #EBE1B0;
  --concretegrey: #7F8076;
}

/* --CSS reset-- */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html, body {
  background: #000;
  color: var(--concretegrey);
}

.logo {
  width: 95%;
  /* min-width: 5rem; */
  max-width: 20rem;
  padding: 0.5rem;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}