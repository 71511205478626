.doors {
    display: flex;
    align-items: center;
    justify-content: center;
}

.doors img {
    padding: 0.5rem;
    width: 100%;
}

.door-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}