nav {
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 1rem;
    padding-bottom: 4rem;
}

.list {
    display: flex;
    gap: 2rem;
    list-style: none;
    padding-bottom: 5rem;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
}

.list a {
    text-decoration: none;
    color: var(--concretegrey);
}

.list a:visited {
    color: var(--concretegrey);
}

.navbtn {
    display: none;
    padding: 0.5rem;
}

.togglebtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
}

.togglebtn .closeimg {
    transform: scale(0.8) rotate(180deg);
}

.togglebtn .openimg {
    transform: scale(0.8);
}

@media screen and (max-width: 768px) {
    
    nav {
        flex-direction: column;
    }

    .list {
        flex-direction: column;
        height: auto;
        gap: 0.5rem;
        position: relative;
        width: 100%;
    }

    .navbtn {
        display: block;
    }
}