p,
h3,
h5 {
    margin: 0.5rem;
}

.product {
    display: flex;
    padding-bottom: 2.5rem;
}

.product-icon {
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-name {
    text-transform: uppercase;
    color: #fff;
}

.product-ingredients {
    text-transform: uppercase;
    /* color: #fff; */
}

.product-description {
    font-style: italic;
}

.product-price {
    color: #fff;
}