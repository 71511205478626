.selector-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.selector {
    border: 1px solid var(--concretegrey);
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selector_text {
    width: 15rem;
    text-align: center;
    text-transform: uppercase;
}

.leftarrow {
    padding-left: 0.5rem;
}

.rightarrow {
    padding-right: 0.5rem;
}