.navicons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.icon-container {
    width: 2.2rem;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.4rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--concretegrey);
    border-radius: 0.3rem;
}

.selected-icon-container {
    border: none;
    background-image: linear-gradient(90deg, var(--concretegrey) 50%, transparent 50%), linear-gradient(90deg, var(--concretegrey) 50%, transparent 50%), linear-gradient(0deg, var(--concretegrey) 50%, transparent 50%), linear-gradient(0deg, var(--concretegrey) 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 5px 2px, 5px 2px, 2px 5px, 2px 5px;
    background-position: left top, right bottom, left bottom, right top;
    animation: marquee 2s infinite linear;
}

.otherdrinks-img {
    padding-left: 0.3rem;
}

.icon-container img {
    scale: 1.2;
}

@keyframes marquee {
    0% {
        background-position: left top, right bottom, left bottom, right top;
    }

    100% {
        background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
    }
}