.dining-table {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dining {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.dining-button {
    width: 12rem;
    padding: 0.5rem;
    border: 1px solid var(--concretegrey);
    border-radius: 0.3rem;
}

.dining-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
}

.dining a {
    text-decoration: none;
    color: var(--concretegrey);
}

.dining a:visited {
    color: var(--concretegrey);
}

.dining p {
    padding-bottom: 0.5rem;
    text-align: center;
}

.dining-fine-print p {
    text-align: left;
}

.dining-text-title {
    font-weight: bold;
    color: #fff;
}

.dining-text-main-title {
    font-weight: bold;
    color: var(--concretegrey);
}

.dining-price {
    padding-top: 2rem;
}

.dining-text-bold {
    font-weight: bold;
    padding-top: 2rem;
}

.dining-text-white {
    color: #fff;
}

@media screen and (max-width: 580px) {
    .dining-table {
        flex-direction: column;
    }
}